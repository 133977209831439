<template>
    <b-modal
            :id="PREFIX + '-modal'"
            hide-footer
            size="lg"
            @hide="resetForm()"
            @shown="initData()"
    >
        <template #modal-title>
            {{action == 'adding' ? $t('label_add_contact') : $t('label_edit_contact')}}
        </template>
        <template #default="{ hide }">
            <!-- Form -->
            <validation-observer
                    #default="{ handleSubmit, invalid }"
                    :ref="PREFIX + '_FORM'"
                    tag="form"
                    class="p-1"
            >

                <validation-provider

                        #default="validationProps"
                        :name="$t('label_deal_number')"
                        rules="required"
                        slim
                >
                    <b-form-group :label="$t('label_category_list')"
                    >

                        <v-select
                                v-model="itemData.id_contact_category"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="contactCategories"
                                :reduce="val => val.id"
                                label="idCountry"
                                :clearable="false"
                                :placeholder="$t('label_select')"
                        >
                            <template v-slot:option="option">
                                {{option.name}}
                            </template>
                            <template v-slot:selected-option="option">
                                {{option.name}}
                            </template>
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>
                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>

                    </b-form-group>
                </validation-provider>
                <div class="mt-2"></div>
                <validation-provider

                        #default="validationProps"
                        :name="$t('label_contact_type')"
                        rules="required"
                        slim
                >
                    <b-form-group :label="$t('label_contact_type')"
                    >
                        <b-form-radio v-for="item in contactTypes"

                                      v-model="itemData.contact_type"
                                      class="mb-1 mt-1"
                                      :value="item.value"
                        >
                            <span style="white-space: nowrap;">{{$t(item.label)}}</span>
                        </b-form-radio>
                    </b-form-group>
                </validation-provider>

                <template v-if="itemData.contact_type == 1">
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_name_of_company')"
                            rules="required"
                            slim

                    >
                        <b-form-group
                                :label="$t('label_name_of_company')"
                        >
                            <b-form-input
                                    v-model="itemData.contact_company_name"
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_name_of_company')"
                            />

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_nip')"
                            rules=""
                            slim

                    >
                        <b-form-group
                                :label="$t('label_nip')"
                        >
                            <b-form-input
                                    v-model="itemData.contact_nip"
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_nip')"
                            />

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_krs')"
                            rules=""
                            slim

                    >
                        <b-form-group
                                :label="$t('label_krs')"
                        >
                            <b-form-input
                                    v-model="itemData.contact_krs"
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_krs')"
                            />

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </template>
                <template v-else>
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_first_name')"
                            rules="required"
                            slim

                    >
                        <b-form-group
                                :label="$t('label_first_name')"
                        >
                            <b-form-input
                                    v-model="itemData.contact_firstname"
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_first_name')"
                            />

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_last_name')"
                            rules="required"
                            slim

                    >
                        <b-form-group
                                :label="$t('label_last_name')"
                        >
                            <b-form-input
                                    v-model="itemData.contact_lastname"
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_last_name')"
                            />

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </template>

                <hr class="mt-3 mb-2">


                <validation-provider
                        #default="validationProps"
                        :name="$t('label_specialization')"
                        rules="required"
                        slim

                >
                    <b-form-group
                            :label="$t('label_specialization')"
                    >
                        <b-form-input
                                v-model="itemData.contact_specialization"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_specialization')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_phone')"
                        rules="required"
                        slim

                >
                    <b-form-group
                            :label="$t('label_phone')"
                    >
                        <b-form-input
                                v-model="itemData.contact_phone"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_phone')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_email_address')"
                        rules="required|email"
                        slim

                >
                    <b-form-group
                            :label="$t('label_email_address')"
                    >
                        <b-form-input
                                v-model="itemData.contact_email"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_email_address')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider

                        #default="validationProps"
                        :name="$t('label_country')"
                        rules="required"
                        slim
                >
                    <b-form-group :label="$t('label_country')"
                    >

                        <v-select
                                v-model="itemData.contact_id_country"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="countries"
                                :reduce="val => val.idCountry"
                                label="idCountry"
                                :clearable="false"
                                :placeholder="$t('label_select')"
                        >
                            <template v-slot:option="option">
                                {{option.countryName}}
                            </template>
                            <template v-slot:selected-option="option">
                                {{option.countryName}}
                            </template>
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>
                        <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>

                    </b-form-group>
                </validation-provider>

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_town')"
                        rules="required"
                        slim

                >
                    <b-form-group
                            :label="$t('label_town')"
                    >
                        <b-form-input
                                v-model="itemData.contact_city"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_town')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                        #default="validationProps"
                        :name="$t('label_street_and_house_number')"
                        rules="required"
                        slim

                >
                    <b-form-group
                            :label="$t('label_street_and_house_number')"
                    >
                        <b-form-input
                                v-model="itemData.contact_street"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_street_and_house_number')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>
                <validation-provider
                        #default="validationProps"
                        :name="$t('label_post_code')"
                        rules="required"
                        slim

                >
                    <b-form-group
                            :label="$t('label_post_code')"
                    >
                        <b-form-input
                                v-model="itemData.contact_zip"
                                :state="getValidationState(validationProps)"
                                :placeholder="$t('label_post_code')"
                        />

                        <b-form-invalid-feedback :state="getValidationState(validationProps)">
                            {{ validationProps.errors[0] }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <template v-if="itemData.contact_type == 1">
                    <h5 class="mt-2">{{$t('label_contact_person')}}</h5>
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_name_and_surname')"
                            rules="required"
                            slim

                    >
                        <b-form-group
                                :label="$t('label_name_and_surname')"
                        >
                            <b-form-input
                                    v-model="itemData.contact_person"
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_name_and_surname')"
                            />

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_email_address')"
                            rules="required|email"
                            slim

                    >
                        <b-form-group
                                :label="$t('label_email_address')"
                        >
                            <b-form-input
                                    v-model="itemData.contact_person_email"
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_email_address')"
                            />

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_phone')"
                            rules="required"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_phone')"
                        >
                            <b-form-input
                                    v-model="itemData.contact_person_phone"
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_phone')"
                            />

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_position')"
                            rules="required"
                            slim
                    >
                        <b-form-group
                                :label="$t('label_position')"
                        >
                            <b-form-input
                                    v-model="itemData.contact_person_position"
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_position')"
                            />

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </template>
                <div class="d-flex justify-content-end mt-2">

                    <button class=" btn btn-default" @click.prevent="$bvModal.hide(PREFIX + '-modal')">
                        {{$t('label_close')}}
                    </button>

                    <button type="submit" @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                            class="btn btn-primary waves-effect waves-light">{{(action ==
                        'editing')?$t('label_save'):$t('label_add')}}
                    </button>
                </div>

            </validation-observer>


        </template>
    </b-modal>
</template>

<script>
    import
    {ValidationProvider, ValidationObserver} from 'vee-validate'

    import {
        CONTACT_REG_PREFIX as MODULE_PREFIX,
        CONTRACT_REG_PREFIX as PREFIX,
        CATEGORY_PREFIX,
        contactTypes
    } from './../moduleHelper'
    import {
        BFormTextarea,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BInputGroupPrepend,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BBadge,
        BFormRadio
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    import infinityScroll from '@/views/components/infinityScroll'

    export default {
        components: {

            BForm,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BFormTextarea,
            BInputGroup,
            BInputGroupPrepend,
            BInputGroupAppend,
            BFormCheckbox,
            BBadge,
            BFormRadio,
            ValidationProvider,
            ValidationObserver,
            vSelect,
            infinityScroll,

        },
        props: ['editedItem'],
        data() {
            return {

                MODULE_PREFIX,
                PREFIX,
                CATEGORY_PREFIX,

                checkboxes: [],

                action: 'adding',

                blankItemData: {
                    id_contact: 0,
                    contact_type: 1,
                    contact_company_name: '',
                    contact_nip: '',
                    contact_krs: '',
                    contact_firstname: '',
                    contact_lastname: '',
                    contact_specialization: '',
                    contact_phone: '',
                    contact_email: '',
                    contact_id_country: null,
                    id_contact_category: null,
                    contact_city: '',
                    contact_street: '',
                    contact_zip: '',
                    contact_person: '',
                    contact_person_email: '',
                    contact_person_phone: '',
                    contact_person_position: '',

                },

                itemData: {},

                contactTypes,

                countries: [],
                contactCategories: []
            }
        },

        methods: {
            initData() {

                if (this.editedItem) {
                    this.action = 'editing';
                    let item = Object.assign({}, this.blankItemData);
                    item.id = this.editedItem.id;
                    for (let prop in item) {
                        if (this.editedItem.hasOwnProperty(prop)) {
                            item[prop] = this.editedItem[prop];
                        }
                    }


                    item.contract_date = formatDate(this.editedItem.contract_date, 'DD/MM/YYYY', 'YYYY-MM-DD');
                    item.contract_end_date = formatDate(this.editedItem.contract_end_date, 'HH:mm', 'YYYY-MM-DD');
                    item.contract_validity_date = formatDate(this.editedItem.contract_validity_date, 'DD/MM/YYYY', 'YYYY-MM-DD');
                    item.contract_end_validity_date = formatDate(this.editedItem.contract_end_validity_date, 'DD/MM/YYYY', 'YYYY-MM-DD');


                    this.itemData = item;

                } else {
                    this.action = 'adding';
                    this.itemData = Object.assign({}, this.blankItemData);

                }

            },
            resetForm() {

                this.itemData = Object.assign({}, this.blankItemData);
                this.$refs[this.PREFIX + '_FORM'].reset();
            },
            onSubmit() {

                if (this.action == 'adding') {
                    this.async('post', '/' + this.MODULE_PREFIX, this.itemData, function (resp) {
                        // this.$emit('item-added', {});
                        // this.$bvModal.hide(this.PREFIX + '-modal');
                        this.$router.push({name: 'contact_registry_details', params: {id: resp.data.item.id_contact}})
                    });

                } else {
                    this.async('put', '/' + this.MODULE_PREFIX + '/' + this.itemData.id_contact, this.itemData, function (resp) {
                        this.$emit('item-edited', {});
                        this.$bvModal.hide(this.PREFIX + '-modal');
                        // this.$router.push({ name: 'contact_registry_details', params: {id: resp.data.item.id_contact} })
                    });

                }

            },


        },

        created() {
            this.async('get', '/countries', {}, (res) => {
                this.countries = res.data
            });
            this.async('get', '/contactregistry_category', {params:{status:1}}, (res) => {
                this.contactCategories = res.data
            });
        }
    }
</script>